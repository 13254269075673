//QA URL's
// export default {
//     //organization structure
//     organizaionCategoryDetailsUrl: "groupmanagement/group-management/v1/customer-group-category",
//     organizaionLocationTypesUrl: "groupmanagement/group-management/v1/customer-group-types",
//     organizaionSubElementCountUrl: "groupmanagement/group-management/v1/customer-groups/sub-customer-groups/",
//     organizaionUnitsDetailsUrl: "unitmanagement/unit-management/unit-customergroup/v1/customergroups/units/",
//     delete_Organization_Element: "groupmanagement/group-management/v1/customer-groups/",
// }


function getRootURL() {
    let backendHost = '';
    backendHost = process.env.REACT_APP_API_URL;
    console.log("backendHost", backendHost);
    return backendHost;
}

export const BaseService = {
    root: getRootURL(),
    root_ws: getRootURL().replace(/^http/, 'ws').replace(/\/$/, ''),
    login: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/login",
    first_login: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/first-login",
    create_Account: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users/parent-account",
    check_valid_email: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/account/validate-user",
    submit_forget_password: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/account/forgot",
    authenticate_reset_password: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/account/reset?username=",
    reset_password: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/account/reset",
    logout: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/signout",
    emulationLogin: "identityaccessmanagement/identity-access-management/user-emulation/v1/user-details",
    emulationLogout: "identityaccessmanagement/identity-access-management/user-emulation/v1/user-signout",
    refreshToken: "identityaccessmanagement/identity-access-management/useraccount-login-nx/v1/refresh-token",
    customer_Group_det: "subscriptionmanagement/subscription-management/subscriptions/get-subscription-customer-group",
    updateSubscription: "subscriptionmanagement/subscription-management/subscriptions/chargify-events-creation",
    getTandCdetails:"subscriptionmanagement/subscription-management/subscriptions/tnc/getUserTnC",
    acceptTandCdetails:"subscriptionmanagement/subscription-management/subscriptions/tnc/acceptUserPolicies"
}

export const ReportManagement = {
    root: "reportmanagement/report-management/production-data/v1",
    countroot: "reportmanagement/report-management/production-data/count/v1",
    panVsTimeLineChart: "reportmanagement/report-management/widgets/bins/v1/states",
    uhcPanVsTimeLineChart: "reportmanagement/report-management/widgets/uhc/bins/v1/states",
    timerDoubleTaps: "reportmanagement/report-management/widgets",
    widgetAPI: "reportmanagement/report-management/widgets",
    fleetReportAPI: "reportmanagement/report-management/fleet-report/v1/connectivity-usage",
    holdingDataPerPan: "reportmanagement/report-management/widgets/bins/v1/holding-data",
    uhcHoldingDataPerPan: "reportmanagement/report-management/widgets/uhc/bins/v1/holding-data",
    utilizationTableView: "reportmanagement/report-management/widgets/bins/v1/utilization",
    uhcUtilizationTableView: "reportmanagement/report-management/widgets/uhc/bins/v1/utilization",
    deviceDataApi: "reportmanagement/report-management/export-report/v1/units",
    uhcdeviceDataApi: "reportmanagement/report-management/uhc/export-report/v1/units",
    fleetReportBrndIdAPI: "subscriptionmanagement/subscription-management/subscriptions/customer-brand",
    overAllPanUtilization: "reportmanagement/report-management/widgets/bins/v1/utilization/percentage",
    uhcOverAllPanUtilization: "reportmanagement/report-management/widgets/uhc/bins/v1/utilization/percentage",
    submitExportReport: "reportmanagement/report-management/generate-report/v1/crem-aggregated",
    productListConfig: "reportmanagement/report-management/exclude-product/v1/config",
    excludeProductDetails: "reportmanagement/report-management/exclude-product/v1/product-list",
    reportList: "reportmanagement/report-management/generate-report/v1/report-list",
    reportDownload: "reportmanagement/report-management/reports/v2/download-reports",
    exportFryerReport: "reportmanagement/report-management/fryer-report/v1/export-reports",
    deleteReport: "reportmanagement/report-management/reports/v2/delete-reports",
    getAutoEmailSetting: "reportmanagement/report-management/auto-email-settings/fetch-config",
    updateAutoEmailSetting: "reportmanagement/report-management/auto-email-settings/update-config",
    updateReportList: "reportmanagement/report-management/fryer-report/v1/update-report-status",
    expobarSteamTemperature: "reportmanagement/report-management/production-data/count/steam",
    garlandMultiWidget: "reportmanagement/report-management/widgets/garlandmultiwidget",
    exportGarlandReport: "reportmanagement/report-management/garland-report/export-garland-reports",
    getReportBrands: "reportmanagement/report-management/generate-report/v1/brand-list",
    reportLibrary: "reportmanagement/report-management/reports/v2/report-library",
    getBrandOptions: "reportmanagement/report-management/reports/v2/brand-options",
    getReportBrandList: "reportmanagement/report-management/reports/v2/brands",
    saveReport: "reportmanagement/report-management/reports/v2/save-report",
    getUnitListBycgidunitid: "reportmanagement/report-management/reports/v2/report-details"
}

export const ServiceManagement = {
    root: "servicemanagement/service-management/v1/unit-errors/",
    totalErrorCount: "servicemanagement/service-management/v1/unit-errors/{deviceIdValue}?viewType=ErrorCount",
    errorList: "servicemanagement/service-management/v1/unit-errors/{deviceIdValue}?viewType=ErrorList&pageSize={pageSizeValue}&pageNum={pageNumValue}",
    emailReport: "servicemanagement/service-management/email-unit-errors",
    frequentErrors: "servicemanagement/service-management/v1/device-errors",
    historicalError: "servicemanagement/service-management/v1/unit-errors/X0/history",
    clearError: "servicemanagement/service-management/v1/unit-errors/{deviceid}/clear",
    deleteError: "servicemanagement/service-management/v1/unit-errors/{deviceId}/history",
    allCurrErrorList: "servicemanagement/service-management/v2/unitCurrentErrorsList",
    allCurrErrorCounts: "servicemanagement/service-management/v2/currentErrorCount",
    allCurrErrorClear: "servicemanagement/service-management/v2/clearCurrentError",
    allHistoryErrorDelete: "servicemanagement/service-management/v2/deleteErrorHistory",
    allHistoricErrorList: "servicemanagement/service-management/v1/unit-errors/history",
}

export const LocationInfo = {
    baseurl: getRootURL(),
    root: "groupmanagement/group-management/v1/customer-group/",
    list: "unitmanagement/unit-management/unit-customergroup/v1/customergroups",
    location: "unitmanagement/unit-management/unit-customergroup/v1/customergroups/units/",
    mapList: "unitmanagement/unit-management/unit-customergroup/v1/customergroups/maps",
    filters: "groupmanagement/group-management/v1/filters",
    connectivityerrorStatusURL: "unitmanagement/unit-management/unit-customergroup/v1/customergroups",
    telefonicaLatLong: 'unitmanagement/unit-management/unit-provisioning/v1/units/lat_long'
}

export const UnitInfo = {
    root: "unitmanagement/unit-management/unit-provisioning/v1/units/",
    list: "unitmanagement/unit-management/unit-customergroup/v1/units",
    unitTemplate: "unitmanagement/unit-management/bulk-unit-registration/v1/downloadTemplate",
    connectivityerrorStatusURL: "unitmanagement/unit-management/unit-provisioning/v1/units/count",
    excludeProduct: "reportmanagement/report-management/exclude-product/v1/product-list",
    unitList: "unitmanagement/unit-management/unit-customergroup/v1/unit-data-list-view",
    unitImage: "unitmanagement/unit-management/unit-customergroup/v1/unitsImage",
    uniqueBrands: "unitmanagement/unit-management/unit-customergroup/v1/brands"
}

export const MediaMenuManagement = {
    root: "filemanagement/file-management/media-management/v1/media/library/",
}

export const UserManagement = {
    root: "identityaccessmanagement/identity-access-management/user-management/v1/users",
    geteditUserDetails: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users/profile/info",
    requestAccess: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users/status-change",
    getMaintenanceText: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/show-text",
    updateEaasCustomer: "subscriptionmanagement/subscription-management/subscriptions/updateEaasCgid",
    allocateUnitReg: "subscriptionmanagement/subscription-management/subscriptions/eaas-allocate-unit-user-assignation"
}

export const userRoleURI = {
    roleBaseAPI: "identityaccessmanagement/identity-access-management/role-management/v1/roles",
    deleteAPI: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/roles",
    getRolesByCustomer: "identityaccessmanagement/identity-access-management/role-management/v1/roles?customer=",
    getAllFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles/features",
    getFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles",
    getRolesBrand: "identityaccessmanagement/identity-access-management/role-management/v1/roles/brands?roleId="
}
export const UserMgmtAddUser = {
    getUserList: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users?customerId=",
    addUser: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users",
    editUser: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/associations",
    deleteUser: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users",
    getCountryCodeApi: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/countrycode",
    get_Orgnanization_View: "groupmanagement/group-management/v1/customer-groups/customers/",
    getAssignedUsers: "identityaccessmanagement/identity-access-management/user-workflow/v1/cgid-users"
}

export const unitAPI = {
    getSerialNumber: "unitmanagement/unit-management/inventory-management/v1/inventories?",
    addUnit: "unitmanagement/unit-management/unit-registration/v1/units",
    getAllFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles/features",
    getBrands: "subscriptionmanagement/subscription-management/subscriptions/brands",
    getFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles",
    getRolesByCustomer: "identityaccessmanagement/identity-access-management/role-management/v1/roles?customer=",
    assignUnitToGroup: "unitmanagement/unit-management/unit-customergroup/v1/assigntounits",
    getModels: "unitmanagement/unit-management/inventory-management/v1/inventories/products",
    getLastSelDetails: "unitmanagement/unit-management/user-history/v1",
    getModelFamilies: "unitmanagement/unit-management/inventory-management/v1/inventories/model_families",
    triggerEmail: "unitmanagement/unit-management/unit-registration/v1/units-registration-notification",
    bulkUploadFile: "unitmanagement/unit-management/bulk-unit-registration/v1/getFileKey?filename=",
    bulkUploadFilePost: "unitmanagement/unit-management/bulk-unit-registration/v1/fileUpload",
    getRegistrationStatus: "unitmanagement/unit-management/bulk-unit-registration/v1/getRegistrationStatus"
}


export const UserProfile = {
    getUserProfileDetails: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users/profile/info",
    putUserProfileDetails: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/profile/info",
    countryCode: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/countrycode",
    countryNameAPI: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/country",
    getNotificationDetails: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/preference",
    get_Orgnanization_View: "groupmanagement/group-management/v1/customer-groups/customers/",
    get_Cust_GroupID: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/customer-group",
    get_Dynacity_Elements: "groupmanagement/group-management/v1/customer-groups-dynasty",
    renameNodeName: "groupmanagement/group-management/v1/customer-groups/category/",
    timenumberpreference: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/timenumberpreference",
    getCustomerAdmin: "subscriptionmanagement/subscription-management/subscriptions/admin?customerId=",
    get_customer_Dynasty: "groupmanagement/group-management/v1/customer-dynasty"
}

export const unitRegister = {
    getUnitsAssignations: "unitmanagement/unit-management/unit-registration/v1/units",
    activateUnits: "unitmanagement/unit-management/unit-registration/v1/units",
    deleteUnits: "unitmanagement/unit-management/unit-registration/v1/units",
    assigUnits: "unitmanagement/unit-management/unit-customergroup/v1/assigntounits",
    unitCertificate: "unitmanagement/unit-management/unit-provisioning/v1/units",
    unitProvisionState: "unitmanagement/unit-management/unit-provisioning/v1/units",
    timeZoneInfo: "unitmanagement/unit-management/unit-registration/v1/timezones",
    getSelNodeTimeZone: "groupmanagement/group-management/v1/timezone",
    updateEaaSFlag: "unitmanagement/unit-management/unit-registration/v1/unflag-eaas"
}

export const OrganizationViewAPI = {
    // get Organization Structure View
    get_Orgnanization_View: "groupmanagement/group-management/v1/customer-groups/customers/",

    // get custumor group categeries
    get_Cust_Grp_Categeries: "groupmanagement/group-management/v1/customer-group-category",

    // get List of Location Types
    get_Types_Of_Locations: "groupmanagement/group-management/v1/customer-group-types",

    // details of particular group(element)
    get_Particular_Cust_Grp_Details: "groupmanagement/group-management/v1/customer-groups/:id",

    // post Create Organization structure
    create_Organization: "groupmanagement/group-management/v1/customer-groups",

    //put edit Organization Structure
    edit_Organization: "groupmanagement/group-management/v1/customer-groups",

    //delete topology element
    delete_Organization_Element: "groupmanagement/group-management/v1/customer-groups/:id",

    //put unassign Unit
    unassign_Unit: "unitmanagement/unit-management/unit-customergroup/v1/unassign-deactivate-units",

    //put activate Or Deactivate Unit 
    active_Deactive_Unit: "unitmanagement/unit-management/unit-registration/v1/units",

    //get Image Upload presigned url
    get_ImageUpload_Presigned: "groupmanagement/group-management/v1/pre-signed-url",

    //get country region url
    getCountryRegion: "unitmanagement/unit-management/unit-customergroup/v1/country-aws-region",

    //drag & drop url
    dragDrop: "groupmanagement/group-management/v1/change-customer-group"

}

export const managePasswordAPI = {
    passwordChange: "identityaccessmanagement/identity-access-management/user-workflow-nx/v1/users/password-change"
}

export const SoftwareManagementAPI = {
    softwareAvailableUpdatesUrl: "filemanagement/file-management/software-management/v1/software",
    softwareUpdateStatusUrl: "filemanagement/file-management/software-management/v1/software/distribution",
    softwareSGStatusList: "filemanagement/file-management/software-management/v1/software/software-smartgroup-view",
    menuSGStatusList: "filemanagement/file-management/menu-management/v1/menu/menu-smartgroup-view",
    softwareSGStatusDelete: "filemanagement/file-management/software-management/v1/software/delete-software-smartgroup",
    menuSGStatusDelete: "filemanagement/file-management/menu-management/v1/menu/delete-menu-smartgroup",
    mediaSGStatusDelete: "filemanagement/file-management/menu-management/v1/menu/menu-smartgroup-view",
    softwareListStatusDelete: "filemanagement/file-management/software-management/v1/software/updateHistory",
    menuListStatusDelete: "filemanagement/file-management/menu-management/v1/menu/updateHistory",
    mediaListStatusDelete: "filemanagement/file-management/media-management/v1/media/updateHistory",
    unitModelInfo: "unitmanagement/unit-management/unit-provisioning/v1/units/",
    unitLocationInfo: "unitmanagement/unit-management/unit-customergroup/v1/customergroups/units/",
    unitSoftwareInfo: "reportmanagement/report-management/fleet-report/v1/connectivity-usage/status",
    getSoftwareBrandList: "filemanagement/file-management/software-management/v1/software/brands",
    bakingPlanList: "filemanagement/file-management/baking-plan",
    bakingPlanUpdateStatus: "filemanagement/file-management/baking-plan/distribution"
}

export const MediaManagementAPI = {
    getUploadHistory: "filemanagement/file-management/media-management/v1/media/distribution",
    getMediaLibraryAPI: "filemanagement/file-management/media-management/v1/media/summary",
    getMediaBrandCustomer: "filemanagement/file-management/media-management/v1/media",
    getMediamediapreview: "filemanagement/file-management/media-management/v1/media/preview/",
    getMedias3uploadpresigned: "filemanagement/file-management/media-management/v1/media/presigned",
    getPresignedUrl: "filemanagement/file-management/media-management/v1/media/presigned",
    getMediaSGStatusList: "filemanagement/file-management/media-management/v1/media/media-smartgroup-view",
    mediaSGStatusDelete: "filemanagement/file-management/media-management/v1/media/delete-media-smartgroup",
}

export const MenuUploadAPI = {
    legacyLibrarylist: "filemanagement/file-management/menu-management/v1",
    getMenuBrandList: "filemanagement/file-management/menu-management/v1/menu/brands",
    getMenuUpdateStatus: "filemanagement/file-management/menu-management/v1/menu/distribution",
    getMenuList: "filemanagement/file-management/menu-management/v1/menu/menu-list",
    getPresignedUrl: "filemanagement/file-management/menu-management/v1/menu/presigned",
    getLatestMenuFileName: "filemanagement/file-management/menu-management/v1/menu/info",
    getLibraryList: "filemanagement/file-management/menu-management/v1/library/libraries",
    getConvoLibraryList: "filemanagement/file-management/menu-management/v1/6/libraries",
    getConvoReadyRecipe: "filemanagement/file-management/menu-management/v1/6/ready-recipes",
    addConvoLibrary: "filemanagement/file-management/menu-management/v1/6/libraries",
    getConvoLibraryInfo: "filemanagement/file-management/menu-management/v1/6/libraries/info",
    createConvoCB: `filemanagement/file-management/menu-management/v1/6/libraries/cook-book`,
    getConvoUploadpresignedUrl: `filemanagement/file-management/menu-management/v1/6/libraries/cb-pre-signed`,
    getRecipeParameters: "filemanagement/file-management/menu-management/v1/recipe/parameters",
    getlibraryUploadpresignedUrl: "filemanagement/file-management/menu-management/v1/library/upload/presigned",
    getlibraryDownloadUrl: "filemanagement/file-management/menu-management/v1/library/download",
    getLibraryUpdateStatus: "filemanagement/file-management/menu-management/v1/library/distribute",
    getConvoUpdateStatus: "filemanagement/file-management/menu-management/v1/6/libraries/distribute",
    getdefaultLibraryFiles: "filemanagement/file-management/menu-management/v1/library/settings",
    getConvolinkpresignedUrl: "filemanagement/file-management/download/file",
    retrieveMenuFromDevice: "filemanagement/file-management/device-log/v1/retrieve",
    updateMenuList: "filemanagement/file-management/menu-management/v1/menu/update-menu",
    retryMenuUpdateStatus: "filemanagement/file-management/menu-management/v1/menu/distribution/retry",
    uploadLibraryFile: "filemanagement/file-management/menu-management/v1/library/upload",
    createCopyLibrary: "filemanagement/file-management/menu-management/v1/library/copy",
    fileRetrieval: "filemanagement/file-management/menu-management/v1/library/retrieve",
    updateLibraryInfo: "filemanagement/file-management/menu-management/v1/library/libraries/info",
    promptValueGet: "filemanagement/file-management/menu-management/v1/menu/prompt"
}

export const HACCPManagementAPI = {
    logLibraryUrl: "filemanagement/file-management/device-log/v1/haccp",
    logViewerUrl: "filemanagement/file-management/device-log/v1/haccp/logviewer",
    unitLogDetails: "unitmanagement/unit-management/unit-provisioning/v1/units/getCloudDetails?unitId=",
    requestLog: "filemanagement/file-management/device-log/v1/haccp",
    downloadFile: "filemanagement/file-management/device-log/v1/haccp",
    getHACCPBrandList: "filemanagement/file-management/device-log/v1/brands",
}

export const WarrantyAPI = {
    Warrantyinformations: "unitmanagement/unit-management/unit-provisioning/v1/units/warrantyInfo",
    connectivityStatus: "unitmanagement/unit-management/unit-provisioning/v1/units/connectivity_status",
}

export const C2CSwaggerAPI = {
    C2CAPIUsageDetails: "subscriptionmanagement/subscription-management/administration/v1/c2c-usage?customerId=",
    C2CAPIUsageDetailsV2: "subscriptionmanagement/subscription-management/administration/v2/apikey/usage",
    C2CAPIGenerateAPIKeyV2: "subscriptionmanagement/subscription-management/administration/v2/apikey/create",
    getApiKey: "subscriptionmanagement/subscription-management/administration/v1/api-keys"
}
export const organizationNode = {
    nodeDetails: "groupmanagement/group-management/v1/customer-groups/nodes/",
}
export const smartGroupAPI = {
    purposeList: "unitmanagement/unit-management/unit-smartGroup/v1/purpose/",
    deletepurposes: "unitmanagement/unit-management/unit-smartGroup/v1/purpose",
    createSmartGroup: "unitmanagement/unit-management/unit-smartGroup/v1/smartGroup",
    createSmartGroupWithUnit: "unitmanagement/unit-management/unit-smartGroup/v1/smartGroupUnits",
    smartGroupUpdateHistory: "unitmanagement/unit-management/unit-smartGroup/v1/smartGroupUpdateHistory",
    deleteSmartGrouplist: "unitmanagement/unit-management/unit-smartGroup/v1/smartGroup",
    smartGroupListAssetMng: "unitmanagement/unit-management/unit-smartGroup/v1/unit/",
    deleteUnitFromSmartGroupList: "unitmanagement/unit-management/unit-smartGroup/v1/unit",
    purposeValidations: "unitmanagement/unit-management/unit-smartGroup/v1/smartGroupPurposeValidation",
}
export const smartGroupStaus = {
    mediaSGCancelStatus: "filemanagement/file-management/media-management/v1/media/cancel-media-smartgroup",
    menuSGCancelStatus: "filemanagement/file-management/menu-management/v1/menu/cancel-menu-smartgroup",
    softwareSGCancelStatus: "filemanagement/file-management/software-management/v1/software/cancel-software-smartgroup",
}

export const NotificationCenter = {
    getNotificationLibrary: "servicemanagement/service-management/automated-notification/v1/event-library",
    subscribeNotification: "servicemanagement/service-management/automated-notification/v1/subscribe",
    workflowDetails: "servicemanagement/service-management/automated-notification/v1/work-flow-node",
    workflowList: "servicemanagement/service-management/automated-notification/v1/work-flow-list"
}

export const DeviceRegistration = {
    validateDeviceRegistration: "unitmanagement/unit-management/inventory-management/v1/validateDeviceRegistration",
    chargifySubscriptionCreation: "subscriptionmanagement/subscription-management/subscriptions/chargify-customer-creation",
    eaasThankyou: "subscriptionmanagement/subscription-management/subscriptions/get-eaas-chargify-subscription-details",
    chargifyConsumerCreation: "subscriptionmanagement/subscription-management/subscriptions/chargify-eaas-consumer-creation",
    getEaasUnit: "groupmanagement/group-management/v1/get-eaas-units",
    eaasAcceptButton: "subscriptionmanagement/subscription-management/subscriptions/updateEaasSubscriptionsStatus",
    eaasEmailConfirmation: "subscriptionmanagement/subscription-management/subscriptions/chargify-creation-success-notification",
    validateEmail: "subscriptionmanagement/subscription-management/subscriptions/validateCustomerEmail",
    jwtTokenAuth: "unitmanagement/unit-management/inventory-management/v1/validateJwtToken",
    getPlanDetailsForSubscription: "subscriptionmanagement/subscription-management/subscriptions/get-subscription-plan-feature-details",
    getPlanTierDetails: "subscriptionmanagement/subscription-management/subscriptions/get-chargify-tier-details",
    getChargifyApiKey: "subscriptionmanagement/subscription-management/subscriptions/get-chargify-public-key",
    getCountryDropdown: "subscriptionmanagement/subscription-management/subscriptions/user-country-region",
    updateSelfSignipSession: "subscriptionmanagement//subscription-management/subscriptions/updateSelfSignUpSession",
    allocationApi: "subscriptionmanagement/subscription-management/subscriptions/allocate-new-unit-chargify",
    updateEaasLastLogin: "subscriptionmanagement/subscription-management/subscriptions/updateEaasloginTransactions",
    updateEaasAllocation: "subscriptionmanagement/subscription-management/subscriptions/eaas-allocate-active-unit-chargify",
    updateHelpPreference: "identityaccessmanagement/identity-access-management/user-association/v1/users/help-preference",
    checkChargifyStatus: "subscriptionmanagement/subscription-management/subscriptions/checkPostChargifySignUpStatus",
    allocateEaasApi: "subscriptionmanagement/subscription-management/subscriptions/eaas-allocate-unit-chargify",
    downgradeApi: "subscriptionmanagement/subscription-management/subscriptions/downgrade-allocate-unit-chargify",
    stateDropdownApi: "subscriptionmanagement/subscription-management/subscriptions/user-country-state",
    generateOTP: "subscriptionmanagement/subscription-management/subscriptions/generateOTPEmail",
    validateOTP: "subscriptionmanagement/subscription-management/subscriptions/validatedCustomerOTPEmail"
}

export const HelpDocumentation = {
    helpFeature: "identityaccessmanagement/identity-access-management/role-management/v1/roles/all-help-features",
    userFeature: "identityaccessmanagement/identity-access-management/role-management/v1/roles/",
    validateHelpToken: "identityaccessmanagement/identity-access-management/role-management/v1/roles/validateJwtToken",
    userFeatureAuth: "identityaccessmanagement/identity-access-management/role-management/v1/roles/"
}

// multilingual
export const LanguagePreference = {
    getLanguages: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/preference/languages",
    updateLocale: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/preference/languages",
    getUserDefaultLocale: "identityaccessmanagement/identity-access-management/user-association-nx/v1/users/language"
}

export const chinaGovRegistrationLink = {
    govLink: ""
}
export const connectivityStatus = {
    deviceConnectivityStatus: "unitmanagement/unit-management/inventory-management/v1/connectivity-test"
}

// SmartTag
export const smartTagMangement = {
    getTagList: "smarttagmanagement/smart-tag-management/v1/smart-tag?tagType=",
    createOrgTag: "smarttagmanagement/smart-tag-management/v1/smart-tag",
    validateTagName: "smarttagmanagement/smart-tag-management/v1/smart-tag/get",
    unitList: "smarttagmanagement/smart-tag-management/v1/units",
    bulkUnit: "smarttagmanagement/smart-tag-management/v1/smart-tag/bulk-create",
    getPresignedUrl: "smarttagmanagement/smart-tag-management/v1/smart-tag/get-presigned-url",
    getTagListView: "smarttagmanagement/smart-tag-management/v1/smart-tag/list?tagType=",
    nodeTagDetails: "smarttagmanagement/smart-tag-management/v1/smart-tag/node/",
    unitTagDetails: "smarttagmanagement/smart-tag-management/v1/units/",
    filterUnitDetails: "smarttagmanagement/smart-tag-management/v1/units/smart-tag",
    transferTagDetails: "smarttagmanagement/smart-tag-management/v1/units/transfer-units-list",
    smartTagBulkUpload: "smarttagmanagement/smart-tag-management/v1/smart-tag/file-upload",
    assignSmartTag: "unitmanagement/unit-management/unit-customergroup/v1/smarttag",
    putUnitMethod: "smarttagmanagement/smart-tag-management/v1/smart-tag/update-unitsmarttag"
}

export const dashboardRenderer = {
    getConfiguration: "reportmanagement/report-management/configuration-service/v1/dashboard-configuration",
    diagnosticEventAction: "servicemanagement/service-management/diagnostic/mode",
    diagnosticDeviceStatus: "servicemanagement/service-management/diagnostic/mode/status?deviceId=",
    liveViewModeEventAction: "servicemanagement/service-management/liveview/mode"
}

export const subscriptionManagement = {
    getInvoice: "subscriptionmanagement/subscription-management/subscriptions/get-chargify-details",
    getFeaturePlan: "subscriptionmanagement/subscription-management/subscriptions/get-subscription-plan-feature-details",
    getSubscriptionList: "subscriptionmanagement/subscription-management/subscriptions/unit-charges",
    getSubscriptionDashboard: "subscriptionmanagement/subscription-management/subscriptions/subscription-dashboard-tial",
    paymentApi: "subscriptionmanagement/subscription-management/subscriptions/payment-profile",
    updateSubscription: "unit-management/unit-registration/v1/units/update-unit-subscription",
    createCustomoer: "subscriptionmanagement/subscription-management/subscriptions/create_customer_chargify",
    getSubscritionDetails: "subscriptionmanagement/subscription-management/subscriptions/subscription-expire-unit"
}