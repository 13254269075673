import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import http from '../../service/httpService';
import $ from 'jquery';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import {UserProfile} from '../../service/api';
import fConsts from '../../utils/featureConstants';
import authService from '../../service/authService';  
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../utils/kcStorage';

const userImages = [
	{ name: "System Manager", img: require("../../images/users/owner.svg").default },
	{ name: "Crem System Admin", img: require("../../images/users/owner.svg").default },	
	{ name: "Service Technician", img: require("../../images/users/service.svg").default },	
	{ name: "1st Line User", img: require("../../images/users/leaser.svg").default },
	{ name: "2nd Line User", img: require("../../images/users/chef.svg").default },
	{ name: "Crem User", img: require("../../images/users/manager.svg").default }
]

class MyInformationEdit extends Component {
	nextPath(path) {
		this.props.history.push("/");
	}
	editInformation() {
		this.props.history.push('/myinformationedit');
	}

	closeModel = () => {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	managePassword() {
		this.props.history.push('/managePassword');
	}
	constructor(props) {
		super(props);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.selectCountryUsingCode = this.selectCountryUsingCode.bind(this);
		this.toggleHidden = this.toggleHidden.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.onChangeEvent=this.onChangeEvent.bind(this);
		this.onChangeEventStreetName=this.onChangeEventStreetName.bind(this);
		this.onChangeEventstreetnumber=this.onChangeEventstreetnumber.bind(this);
		this.nextPath = this.nextPath.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.IsAlphaNumeric=this.IsAlphaNumeric.bind(this);

		this.state = {
			isHidden: false,
			userData: {},
			countryCodeWithoutPlus:'',
			countyData: [],
			countyNameData: [],
			postData: {},
			textvalue: "",
			typedStreatName:'',
			typedStreatNo:'',
			typedCellNo:'',
			enterCellNo:'',
			selectedCountryName:'',
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal:{
				open:false,
				message:""
			  },
			user: {
				name: "vinothkumar",
				customerId: KCStore.getItem("customerId"),
				phoneNumber: "9930421122",
				email: "test3@hcl.com",
				streetname: "Choolaimedu",
				streetnumber: "120G",
				zipcode: "600094",
				city: "Chennai",
				countryname: "India",
				countryCode: "91234",
				state: "Tamilnadu",
			},
			emailfromTokan: '',
			userstate: '',
			catchSelCountryCode: '',
		};
	}

	onSuccessCloseModal() {
		this.setState({
			success_modal: {
				open: false,
				message: "KC0977"
			},
		});
		//this.props.history.push('/myinformationedit');
	}

	onChangeEvent = event => {
		// if (event.target.value != "")
		// {			
		if (this.state.enterCellNo.length < 6) {
			this.setState({
				typedCellNo: '',
				enterCellNo: event.target.value,
			})
		}
		else {
			const { value, maxLength } = event.target;
			const message = value.slice(0, maxLength);
			this.setState({ enterCellNo: message });
			this.setState({
				typedCellNo: this.state.enterCellNo,
			})
		}
		// }	
	}

	onChangeEventStreetName(e){
		this.setState({			
			typedStreatName:this.refs.street_name.value.trim(), 
		})
	}

	onChangeEventstreetnumber(e){		
		this.setState({			
			typedStreatNo:this.refs.street_number.value,   
		})
	}

	IsAlphaNumeric(e) {
		e.target.value = e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
	}

	getCountryCode = () => {
		http.get(UserProfile.countryCode,
			{ headers: { 'Content-Type': 'application/json' },data:{} }
		).then(response => {
			this.setState({ countyData: response.data.data })
		}).catch(err => {
			console.log('catch exception occured');
		});
	}

	selectCountryUsingCode = (e, val) => {		
		let countCode = ""
		if (val == "" || val == undefined) {
			countCode = e.target.value;
		}
		else {
			countCode = val;
		}

		http({
			method: "get",			
			url: `${UserProfile.countryNameAPI}?removeCache=${new Date().getTime()}`,
			headers: {
				"country_code": countCode,
				'Content-Type': 'application/json'
			},data: {}
		}).then(response => {
			if (response.data.data != "No Records Found") {
				this.setState({ countyNameData: response.data.data })
				this.setState({ catchSelCountryCode: countCode })
				if(response.data.data.length>1) {
					let getCountryName = this.state.selectedCountryName;
					let newCountryName = response.data.data.filter((item, i) => {
						return item.name === getCountryName;
					});
					this.setState({ selectedCountryName: newCountryName[0].name })
				} else { this.setState({ selectedCountryName: response.data.data[0].name }) }
				$("#countrycode").val(this.state.catchSelCountryCode);
			} else {
				this.setState({ countyNameData: [] })
				this.setState({ catchSelCountryCode: '' })
			}
		}).catch(err => {
			console.log('catch exception occured');
		});
	}


	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	countryChangeEvent= (e) => {
		this.setState( {selectedCountryName:e.target.value });		
	}

	editUserSubmit = (e) => {
		if (this.refs.street_name.value!= undefined)
		{
			this.setState({
				isStreatNameTyped:true,
				loaderIconVisible: true,
			})
		}
		const { locale, languageList } = this.props;
		const langFilter = languageList.filter((i) =>i.language_code === locale);
		let postData = {
			name: this.state.userData.name,
			first_name: this.state.userData.first_name,
			last_name: this.state.userData.last_name,
			customer_id: this.state.userData.Id.toString(),
			phone_number: this.refs.phone_number.value,
			email: this.refs.email.value,
			street_name: this.refs.street_name.value,
			street_number: this.refs.street_number.value,
			zip_code: this.refs.zip_code.value,
			city: this.refs.city.value,
			state: this.state.userstate,
			language_id: langFilter[0].Id
		};		
		if (this.refs.countrycode != undefined) {
			let country_code = this.state.catchSelCountryCode
			Object.assign(postData, { country_code })

			let country=this.state.selectedCountryName
			Object.assign(postData, { country })
		}

		for (const property in postData) {
			if (postData[property] == null) {
				postData[property] = '';
			}
		}

		http({
			method: "PUT",			
			url: `${UserProfile.putUserProfileDetails}`,
			data: { user: postData },
		}).then(function (response) {
			let customerInfo =   JSON.parse(KCStore.getItem("customerInfoDetails"));
			let reframeCustInfo  = {
				city: postData.city,
				country: postData.country,
				country_code: postData.country_code,
				customer_name: customerInfo.customer_name,
				email: customerInfo.email,
				first_name: customerInfo.first_name,
				last_name: customerInfo.last_name,
				name: customerInfo.name,
				phone_number: postData.phone_number,
				role_id: customerInfo.role_id,
				role_name: customerInfo.role_name,
				state: postData.state,
				street_name: postData.street_name,
				street_number: postData.street_number,
				zip_code: postData.zip_code,

			}
			if (reframeCustInfo){
				KCStore.setItem('customerInfoDetails', JSON.stringify(reframeCustInfo));
			}
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: response.data
				},
			});
			WoopraEvents(`${Constants.USER_PROFILE_UPDATED}`)
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC0977"
				},
			});
		});
	}

	componentDidMount() {
		this.getUserProfileInformation();
		this.getCountryCode();
		document.body.style.backgroundColor = "#F2F2F2";
	}
	
	getUserProfileInformation = () => {	
		let loginUser =   JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		this.setState({ emailfromTokan: loggedinUser,loaderIconVisible: true, });
		http({
			method: "GET",			
			url: `${UserProfile.getUserProfileDetails}`,
			headers: {
				'customerid': KCStore.getItem("customerId"),
				'email': loggedinUser,
				'Content-Type': 'application/json',
				'basecountry': KCStore.getItem("basecountry"),
				'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
			},data:{},
		}).then(response => {
			var countycodeReplaceplus=response.data.country_code.replace("+","")
			countycodeReplaceplus = "+"+ countycodeReplaceplus		
			this.setState({
				userData: response.data,
				countryCodeWithoutPlus: {countycodeReplaceplus},				
			})
			this.setState({ userstate: response.data.state });
			this.setState({ catchSelCountryCode: countycodeReplaceplus })
			this.setState({
				typedStreatName:response.data.street_name,
				typedStreatNo:response.data.street_number,
				typedCellNo:response.data.phone_number,
				enterCellNo:response.data.phone_number,
				loaderIconVisible: false,
			})
			$("#countrycode").val(this.state.catchSelCountryCode);
			$("#countrynamed").val("United States Minor Outlying Islands");
			this.setState( {selectedCountryName:response.data.country });			
			this.selectCountryUsingCode("", this.state.catchSelCountryCode);

		}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					// error_modal:{
					// 	open:true,
					// 	message: "Your session has expired. Please login again."
					// }
					
				 });
				//  KCStore.removeItem("Access-Token");
				//  this.props.history.push(`/`) 				
			});
	}

	toggleHidden(event) {
		event.preventDefault();

		if (!this.state.isHidden) {
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);

		}
		this.setState({
			isHidden: !this.state.isHidden
		})
	}

	handleOutsideClick(event) {
		// ignore clicks on the component itself             
		if (this.pop) {
			//const domNode = ReactDOM.findDOMNode(this.pop)
			if (this.pop.contains(event.target)) {
				return;
			}
			this.toggleHidden(event);
		}
	}

	ViewAccountInfo = (props) =>
	{		
		let userRoleImage = props.userRoleImage;
		let userData = props.userData;	
		let countryCodeWithoutPlus = userData.country_code ? userData.country_code.indexOf('+') === -1 ? '+' : "": "";
		let phonenumandcountrycode=  userData.country_code ? (<h4 x-ms-format-detection="none"> { countryCodeWithoutPlus + userData.country_code }&nbsp;{userData.phone_number}</h4>) : "";

		return (<form class="myInformationForm">
		<div class="myInformationTable">
			<ul class="tHead">
				<li><span class="userProfileImg"><img src={userRoleImage} alt=""/></span></li>
				<li>
					<h3>{userData.name}</h3>
					<h4>{userData.role_name}</h4>
				</li>
				<li>&nbsp;</li>
			</ul>
			<ul class="tBody">
				<li>&nbsp;</li>
				<li>
					<h4>{userData.email}</h4>
					{phonenumandcountrycode}
				</li>
				<li>
					<h5>{userData.street_name} {userData.street_number}</h5>
					<h5>{userData.zip_code} {userData.city}</h5>
					<h5>{userData.country}</h5>
				</li>
			</ul>
		</div>
	</form>)
	}

	// Woopra Events
	trackEvent = (e) => {
		let title = e.trackTitle === "Time & Number Format" ? "Time and Number Format" : e.trackTitle;
		WoopraEvents(`${title}`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { userData, success_modal,error_modal, countyData, countyNameData,
			typedStreatName,loaderIconVisible,typedStreatNo,typedCellNo, enterCellNo } = this.state;
		let isValidate=false;
		if (enterCellNo.length >= 7 && typedCellNo) {
			isValidate = false
		} else {
			isValidate = true
			if (enterCellNo.length <= 6)
				typedCellNo = ''
		}

		// if( this.state.typedStreatName == null || this.state.typedStreatNo == null || this.state.typedCellNo == null)
		// {
		// 	isValidate=true
		// }
		
		let pickRole = userImages.find(r => r.name === userData.role_name);        
        let userRoleImage="";
        if (pickRole != null) {
             userRoleImage = pickRole.img;
		} 
		else if(pickRole === undefined)
		{
			userRoleImage=require("../../images/users/user.png");
		}

		let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let userProfileFeature = authService.getFeatureByFeatureName(fConsts.UserProfile);
	 
		let NotificationManagementEditOrViewLink;		
		let isUserProfileFormViewStatus;
		let myinformationEditOrViewLink="/myinformationEdit";
		let assignedunitgroupsEditOrViewLink="/assignedunitgroupsEdit";
		if (userNotificationAuthFeature && userNotificationAuthFeature.feature_active)
		{
			NotificationManagementEditOrViewLink =  "/NotificationManagement";
		}	
		if(userProfileFeature && userProfileFeature.feature_active) {				
				isUserProfileFormViewStatus = userProfileFeature.is_editable ? false : true;
		}

		return (			
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress -->*/}

					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={myinformationEditOrViewLink} title={formatMessage({id: 'KC1916'})} class="active"><FormattedMessage id='KC1916' /></Link>
										<Link to={assignedunitgroupsEditOrViewLink} title={formatMessage({id: 'KC1917'})} onClick={(e)=> {e.trackTitle="Assigned Unit Groups";this.trackEvent(e)}}><FormattedMessage id='KC1917' /></Link>
										{ NotificationManagementEditOrViewLink &&
										  <Link to={{pathname:NotificationManagementEditOrViewLink, state : {fromViewAccountpageValue: false}}} title={formatMessage({id:'KC0109'})}  onClick={(e)=> {e.trackTitle="Notification Management";this.trackEvent(e)}}><FormattedMessage id='KC0109' /></Link> }
										  <Link to="/timeNumberFormatedit" title={formatMessage({id: 'KC0110'})}  onClick={(e)=> {e.trackTitle="Time & Number Format";this.trackEvent(e)}}><FormattedMessage id='KC0110' /></Link>
										  {/* <Link to="/mySubscriptionEdit" title="Subscription Management">Subscription Management</Link> */}
										  <Link to="/termsPricingCondition" title={formatMessage({id: 'KC2439'})} ><FormattedMessage id='KC2439' /></Link>
									</li>
								</ul>
							</div>

						{ isUserProfileFormViewStatus ? 
						  this.ViewAccountInfo({userRoleImage, userData }) :  
						  <form class="myInformationForm">
								<div class="myInformationTable editInfoTable">
									<ul class="tHead">
										<li>
											<span class="userProfileImg"><img alt="" src={userRoleImage} /></span>
											<a href="#" class="ChangePicture"><FormattedMessage id="KC0117"/></a>
										</li>
										<li>
											<h3>{userData.name}</h3>
											<h4>{userData.role_name}</h4>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul class="tBody">
										<li>&nbsp;</li>
										<li>
											<div class="addUserForm">
												<form>
													<ul>
														<li>
															<div class="input-group">
																<input ref="email" disabled={true} className="form-control" name="email" type="text" value={this.state.emailfromTokan} />
															</div>
														</li>

														<li>
															<div class="input-group">														
																<select id="countrycode" class="form-control countryCode" defaultValue={this.state.countryCodeWithoutPlus}  onChange={(e) => this.selectCountryUsingCode(e)}>
																	{countyData.map((item, i) =>
																		<option ref="countrycode" key={i} defaultValue={this.state.catchSelCountryCode}>{item.code}</option>
																	)}
																</select>															
																<input type="number"  maxLength="15" onKeyUp={(e) => this.IsAlphaNumeric(e)} ondrop="return false;" onpaste="return false;" ref="phone_number" class="form-control phoneNumber" placeholder={formatMessage({id:'KC0118'})}   value={this.state.enterCellNo} onChange = {(e) => this.onChangeEvent(e)}  onBlur = {(e) => this.onChangeEvent(e)}  />																														
															</div>
														</li>
														<li class="streetName">
															<div class="input-group">
																<input type="text" class="form-control" placeholder={formatMessage({id:'KC0120'})} ref="street_name" defaultValue={userData.street_name} onChange = {(e) => this.onChangeEventStreetName(e)}  onBlur = {(e) => this.onChangeEventStreetName(e)} />
																{/* <span class="requiredField">*</span> */}
																{/* { !typedStreatName ? <div className="field-validation-error">{"Street Name is required"}</div> :"" } */}
															</div>
														</li>
														<li class="streetNumber">
															<div class="input-group">
																<input type="text" class="form-control" onKeyUp={(e) => this.IsAlphaNumeric(e)} ondrop="return false;" onpaste="return false;" placeholder={formatMessage({id:'KC0121'})} ref="street_number" defaultValue={userData.street_number} onChange = {(e) => this.onChangeEventstreetnumber(e)} onBlur = {(e) => this.onChangeEventstreetnumber(e)} />
																{/* <span class="requiredField">*</span> */}
																{/* { !typedStreatNo ? <div className="field-validation-error">{"Street Number is required"}</div> :"" } */}
															</div>
														</li>
														<li class="zipcode">
															<div class="input-group">
																<input type="text" onKeyUp={(e) => this.IsAlphaNumeric(e)} ondrop="return false;" onpaste="return false;" class="form-control" placeholder={formatMessage({id:'KC0122'})} ref="zip_code" defaultValue={userData.zip_code} />
															</div>
														</li>
														<li class="cityName">
															<div class="input-group">
																<input type="text" class="form-control" placeholder={formatMessage({id:'KC0098'})} ref="city" defaultValue={userData.city} />
															</div>
														</li>
														<li>
															<div class="input-group">
																<select ref="selectEl" id="countrynamed" class={countyNameData.length > 1 ? "form-control" : "form-control singleoption disabled"} value={this.state.selectedCountryName}   onChange={(e) => this.countryChangeEvent(e)} >																
																	{countyNameData.map((item, i) =>																	
																		<option ref="countryname"  key={i} defaultValue ={this.state.selectedCountryName} >{countyNameData[i].name}</option>
																	)}
																</select>
															</div>
														</li>
													</ul>
													<button type="button" class="btn profileUpdateBtn" onClick={(e) => this.editUserSubmit(e)}><FormattedMessage id='KC0123' /> </button>&nbsp;&nbsp;
													{ KCStore.getItem("isSSOLogin") === "true"?"":
														<button type="button" class="btn profileUpdateBtn" onClick={(e) => this.managePassword(e)}><FormattedMessage id='KC0108' /></button>
																	}																					
													<div class="requiredNoteDiv"><FormattedMessage id="KC0130"/></div>
												</form>
											</div>
										</li>
										<li>
											<div class="addUserForm">
												<form>
													<ul>
														<li class="infoLi">
															<p class="formInfoIcon"><FormattedMessage id='KC1665' /></p>
														</li>
													</ul>
												</form>
											</div>
										</li>
									</ul>
								</div>


							</form>}

						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel}/>
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />					
					<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106"  previousPage="User Profile" activeClass="UserProfileNav"/>	
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	// debugger;
	return {
		locale: state.localeInfo.locale,
        languageList: state.localeInfo.languages,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyInformationEdit));

const userProfileMockData = {
	"user": {
		"name": "",
		"Id": "1",
		"phone_number": "",
		"email": "",
		"street_name": "",
		"street_number": "",
		"zip_code": "",
		"city": "",
		"country": "",
		"state": ""
	}
}

