import jwtDecode from "jwt-decode";
import http from './httpService';
import authHelper from '../utils/authHelper';
import authConstants from '../utils/authConstants';
import { setBrandId }  from '../utils/appConstants';
import * as API from './api';
import { encriptData } from "../utils/dashboardRenderer";

import KCStore from '../utils/kcStorage';

const { tokenKey, loginInfo, rememberMe, tokenExpireTime, allFeaturesKey, currentFeaturesKey, 
    refreshToken, refreshTokenErrorStatus, signoutStatus, isSSOLoginInfo }  = authConstants;

export const login = (postData, isRemember) => {
    return new Promise(async (resolve, reject)=> {
        // const newPayload = await encriptData(JSON.stringify(postData))
        http.post(API.BaseService.login, { credential: postData }).then(response => {  
        const { data : responseData } = response;
            console.log("responseData =============== ",responseData)

            let { customerId } = responseData;
            if(customerId !== undefined && customerId !== ''){ 	 

            // var passwordVal = Buffer.from(postData.password);
			// var password_Encry = passwordVal.toString('base64');
			let encode_postData ={
				username:postData.username, 
				// password:password_Encry,			
			}

            KCStore.setItem(loginInfo, JSON.stringify(encode_postData)); 
            KCStore.setItem(isSSOLoginInfo, false);  
            http.setJwt(getJwt()); 
            //setLocalStorage(responseData, isRemember); //we shld not store all the details in local storage immediately, once user accept the T&C then we need to store in local storage
            setRememberMe(isRemember);
            // setJwt(responseData.accessToken);
            
            let rolefeatures = responseData.features;
            KCStore.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

            //Store BrandIds of the User
            KCStore.setItem(authConstants.userBrands, JSON.stringify(responseData.brandIds));
            setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
            http.setJwt(getJwt());  
                      
            //Set Features
            let currentFeatures = authHelper.applyPermissions(rolefeatures);
            KCStore.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

            // Set Refresh Token
            KCStore.setItem(refreshToken, JSON.stringify(responseData.refreshToken));


            // isRemember && KCStore.setItem(rememberMe, isRemember);
            resolve(response);
            setFeatures(rolefeatures).then(isSucceed=>{
                    isRemember && KCStore.setItem(rememberMe, isRemember);
                    resolve(response);
            }).catch(err=>{
                reject(err);
            });                    
           
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

export const setRememberMe = (isRemember) => {
    KCStore.setItem(rememberMe, isRemember);
}

export const getRememberMe = () =>{
    let isRemember = JSON.parse(KCStore.getItem(rememberMe));
    return isRemember;
}

export const getCurrentUser = () => {
    try {
      const jwt = getJwt();
      return jwtDecode(jwt);
    } catch (ex) {
      return null;
    }
}

export const getJwt = () =>{
    // let token ="", cookieValue, requestCookie;
    // cookieValue = "; " + document.cookie;
    // requestCookie = cookieValue.split("; " + tokenKey + "=");
    // if (requestCookie.length === 2) 
    //   token = requestCookie.pop().split(";").shift();
    return KCStore.getItem(tokenKey);
}

export const setJwt = (token) => { 
    KCStore.setItem(tokenKey, token);
    document.cookie = `${tokenKey}=${token}`;   
}

export const deleteJwt = () =>
{
    KCStore.removeItem(tokenKey);
    // try{
    // let token = getJwt();
    // let jwt = token && jwtDecode(token);
    // document.cookie = token && (tokenKey + `=;expires=${new Date(jwt.exp * 1000)};`);
    // }
    // catch {
    //     return false;
    // }
}

export const IsTokenTimeExipired = () =>{    
    let errorStatus = KCStore.getItem(refreshTokenErrorStatus);
    let token = KCStore.getItem(refreshToken);

    let exp = KCStore.getItem(tokenExpireTime);
    let remember = KCStore.getItem(rememberMe);

    let expireTime = new Date(exp);
    let currentTime = new Date();
    let isExpired = currentTime > expireTime ;
    console.log({expireTime, currentTime, isExpired, remember});

    console.log("typeof = ",typeof token,", remember = ",remember, ", type = ",typeof remember);
    console.log("errorStatus = ",errorStatus, ", type of errorStatus = ",typeof errorStatus);
    if((!remember && isExpired) ||errorStatus === 'true' || token === 'undefined' || token == null || token === undefined|| token === '') {
        return true;
    } else {
        return false;   
    }

}

/**
 * Store Current Features 
 * @param {*} serverFeatures 
 */
export const setFeatures = (rolefeatures) =>{
    let currentFeatures = authHelper.applyPermissions(rolefeatures);
    KCStore.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));
}

/**
 * Get Single Feature by route path
 * @param {*} route_path 
 */
export const getFeature = (route_path) =>{
    route_path = route_path.indexOf("role/add") !== -1 ? "/role/add" : route_path;
    let featuers = JSON.parse(KCStore.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(featuers && featuers.length>0)
    for (let index = 0; index < featuers.length; index++) {
        let productedRoutes = featuers[index].productedRoutes;
        let routeAuthorized = productedRoutes.filter(f => f === route_path && featuers[index].feature_active === 1);
        if(routeAuthorized && routeAuthorized.length>0){
            authorizedFeature = featuers[index];
            break;
        }     
    };
    return authorizedFeature;
}

/**
 * Get all features by rendering Main Menu Apps.
 */
export const getAllFeatures = () =>{
    let featuers =JSON.parse(KCStore.getItem(currentFeaturesKey));
    const isSubscription = featuers.filter((i) => i.linkTitle === "Subscription Management");
    if (isSubscription.length) {
        const findIndex = featuers.findIndex((i) => i.linkTitle === "Subscription Management");
        featuers.splice(findIndex, 1);
        featuers.push(isSubscription[0])
    }
    let authorizedFeatures = featuers.filter(f => f.feature_active === 1);
    return authorizedFeatures;
}

export const clearLastSessionData = () =>{
    let cfeatuers = JSON.parse(KCStore.getItem(currentFeaturesKey));
    let allfeatuers = JSON.parse(KCStore.getItem(allFeaturesKey));   
    if(!cfeatuers || !allfeatuers){
       authHelper.deleteLocalSession();
     }
}

export const getLoginUserBrands = () =>{
    let userBrands = KCStore.getItem(authConstants.userBrands);
    userBrands = userBrands && JSON.parse(userBrands);
    console.log("userBrands = ",userBrands)
    return userBrands && userBrands.length >0 ? userBrands : [];
}

/**
 * Get Single Feature by Feature Name
 * @param {*} feature_name 
 */
export const getFeatureByFeatureName = (feature_name) =>{
    let features = JSON.parse(KCStore.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(features && features.length>0)
    {
        authorizedFeature = features.find(f => f.feature_name === feature_name && f.feature_active === 1);
    }
    return authorizedFeature;
}

export const setLocalStorage = async (responseData, isRemember) =>{  

    setJwt(responseData.accessToken);
    
    let rolefeatures = responseData.features;
    KCStore.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

    //Store BrandIds of the User
    KCStore.setItem(authConstants.userBrands, JSON.stringify(responseData.brandIds));
    setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
              
    //Set Features
    let currentFeatures = authHelper.applyPermissions(rolefeatures);
    KCStore.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

    // Set Refresh Token
    KCStore.setItem(refreshToken, responseData.refreshToken);
    KCStore.setItem(refreshTokenErrorStatus, false);

    isRemember && KCStore.setItem(rememberMe, isRemember);  
    KCStore.setItem(signoutStatus, false);

	// TODO: Needed to resolve during hardening! Added calculation expTime
	//

    let token = KCStore.getItem(refreshToken);
    let expTime = KCStore.getItem(tokenExpireTime);
    let date = new Date();
    let currentTime = new Date();
    let expireTime = expTime ? new Date(expTime) : new Date(date);
    // date.setHours(date.getHours() + 1);
    date.setMinutes(date.getMinutes() + 1);
    let isExpired = currentTime > expireTime ;
    let rememberMe = KCStore.getItem('RememberMe');
    if (token && !isExpired) {
        KCStore.setItem(tokenExpireTime, date);
    }

    const preferenceValue = await responseData.userTimeNumberPreferences ? responseData.userTimeNumberPreferences.filter((i)=>i.preference_name === "help-preference") :  []
    KCStore.setItem('showHelpModal', preferenceValue.length ? preferenceValue[0].preference_value === "1" ? true : false : false)
}

export const getSSOLogin = (code) => {
    return new Promise(async (resolve, reject)=> {
        const newPayload = await encriptData(JSON.stringify(code))
        http.post(API.BaseService.sso_login, { credential: newPayload }).then(response => { 
            const { data : responseData } = response;
            console.log("responseData =============== ",responseData)

            let { customerId, username, isSSOLogin } = responseData;
            if(customerId !== undefined && customerId !== '')
            { 	
                let encode_postData ={
                    username: username,		
                }

                KCStore.setItem(loginInfo, JSON.stringify(encode_postData)); 
                KCStore.setItem(isSSOLoginInfo, isSSOLogin);  
                http.setJwt(getJwt()); 
                resolve(response);                         
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

export const getSSOCognitoURL = (uuid) => {
    return new Promise(async (resolve, reject)=> {
        const newPayload = await encriptData(JSON.stringify(uuid))
        http.post(API.BaseService.sso_cognito_url, { credential: newPayload }).then(response => {  
            console.log("response: ", response);
            const { data : responseData } = response;
            console.log("responseData =============== ",responseData)

            let { cognito_url } = responseData;
            if(cognito_url !== undefined && cognito_url !== ''){ 	
                resolve(response);
            }
            else {
                reject(response);
            }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

export default {
    login,
    getJwt,
    getCurrentUser,
    setRememberMe,
    getRememberMe,
    setJwt,
    deleteJwt,
    IsTokenTimeExipired,
    getFeature,
    getAllFeatures,
    clearLastSessionData,
    getLoginUserBrands,
    getFeatureByFeatureName,
    setLocalStorage,
    getSSOLogin,
    getSSOCognitoURL
};
 